
.ForgeViewer{
  width:100%;
  height:100%;
}

.ForgeViewer .viewer{
  position:relative;
  width:100%;
  height:100%;
}

.ForgeViewer .scrim{
  position:relative;
  width: 100%;
  height:100%;
  z-index: 1000;
  background-color:#ededed;
  color: #95a5a6;
  display:flex;
  align-items: center;
  justify-content: center;
}

.ForgeViewer .scrim .message{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.ForgeViewer .scrim .message svg{
  margin-bottom:10px;
  width:50px;
  height:50px;
}